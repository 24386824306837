import React, { useState } from 'react';
import NavBar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { HeroContainer, HeroContent, HeroItems, HeroH1, HeroP, HeroBtn } from './HeroElements';

function Hero() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeroContainer>
      <NavBar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HeroContent>
        <HeroItems>
          <HeroH1>Pizzas deliciosas por um preço justo</HeroH1>
          <HeroP>Kikiki Pizzas</HeroP>
          <a href='https://wa.me/5522981595645?text=Olá, gostaria de fazer um pedido' target='_blank' rel='noopener noreferrer'>
            <HeroBtn>Peça já</HeroBtn>
          </a>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
}

export default Hero;
