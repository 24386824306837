import product1 from '../../images/pizzas/brocolis.jpg';
import product2 from '../../images/pizzas/calabresa.jpg';
import product3 from '../../images/pizzas/frango.jpg';
import product4 from '../../images/pizzas/margueritta.jpg';
import product5 from '../../images/pizzas/mussarela.jpg';
import product6 from '../../images/pizzas/portuguesa.jpg';
import product7 from '../../images/pizzas/presunto.jpg';

import sweet1 from '../../images/sweet3.jpg';
import sweet2 from '../../images/sweet-2.jpg';
import sweet3 from '../../images/sweet-3.jpg';

export const productData = [
  {
    img: product1,
    alt: 'Pizza',
    name: 'Brócolis',
    desc: 'Molho de tomate, mussarela, orégano, brócolis, alho torrado',
    price: 'R$35',
    button: 'Adicionar ao carrinho',
  },
  {
    img: product2,
    alt: 'Pizza',
    name: 'Calabresa',
    desc: 'Molho de tomate, mussarela, tomate, cebola, orégano, calabresa',
    price: 'R$35',
    button: 'Adicionar ao carrinho',
  },
  {
    img: product3,
    alt: 'Pizza',
    name: 'Frango C/ Catupiry',
    desc: 'Molho de tomate, mussarela, orégano, frango desfiado, Catupiry',
    price: 'R$35',
    button: 'Adicionar ao carrinho',
  },
  {
    img: product4,
    alt: 'Pizza',
    name: 'Margueritta',
    desc: 'Molho de tomate, mussarela, tomate, orégano, manjericão',
    price: 'R$30',
    button: 'Adicionar ao carrinho',
  },
  {
    img: product5,
    alt: 'Pizza',
    name: 'Mussarela',
    desc: 'Molho de tomate, mussarela, orégano',
    price: 'R$30',
    button: 'Adicionar ao carrinho',
  },
  {
    img: product6,
    alt: 'Pizza',
    name: 'Portuguesa',
    desc: 'Molho de tomate, mussarela, tomate, pimentão, cebola, orégano, ovo, presunto',
    price: 'R$35',
    button: 'Adicionar ao carrinho',
  },
  {
    img: product7,
    alt: 'Pizza',
    name: 'Presunto',
    desc: 'Molho de tomate, mussarela, tomate, cebola, orégano, presunto',
    price: 'R$30',
    button: 'Adicionar ao carrinho',
  },
];

export const productDataTwo = [
  {
    img: sweet2,
    alt: 'Doughnuts',
    name: 'Doughlicious',
    desc: 'Belgian chocolate glazed doughnuts covered in icing with sprinkles on top',
    price: 'Ksh 100',
    button: 'Add to Cart',
  },
  {
    img: sweet3,
    alt: 'Ice Cream',
    name: 'Caramel Wonder',
    desc: 'Vanilla ice cream covered with caramel and chocolate glaze topped with a coco stick',
    price: 'Ksh 130',
    button: 'Add to Cart',
  },
  {
    img: sweet1,
    alt: 'Brownie',
    name: 'Brownie Bunch',
    desc: 'Double fudge brownie squares topped with white chocolate pieces and macadamia nuts',
    price: 'Ksh 100',
    button: 'Add to Cart',
  },
];
