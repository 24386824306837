import React from 'react';
import { FeatureContainer, FeatureButton } from './FeatureElements';

function Feature() {
  return (
    <FeatureContainer>
      <h1>Promoção do dia</h1>
      <p>Pizza 8 Fatias, até 2 Sabores + Refrigerante 1,5l por R$39,99</p>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://wa.me/5522981595645?text=Olá, gostaria de fazer um pedido de "Pizza 8 Fatias, até 2 Sabores e Refrigerante 1,5l por R$39,99"'
      >
        <FeatureButton>Peça já</FeatureButton>
      </a>
    </FeatureContainer>
  );
}

export default Feature;
